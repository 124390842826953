<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-TPR6WNSTR9"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-TPR6WNSTR9');
</script>
<meta name="keywords" content="menu digitale, menu, ristorante, qrcode menu, ristorazione, ristorante digitale, menu online">
<meta name="description" content="Crea e gestisci con semplicità i Menu del tuo ristorante, bar o pizzeria
e scopri quali piatti attirano di più i clienti. Il menù digitale per crescere ed evolvere la tua attività.">
<app-preloader></app-preloader>
<router-outlet (activate)="onActivate($event)"></router-outlet>
