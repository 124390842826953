import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './helpers/auth.guard';

const routes: Routes = [
    // Landing
    { path: '', loadChildren: () => import('./components/pages/prebuilt-pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule), data: { breadcrumb: "H-Menu" } },
    // Home
    { path: 'dashboard', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard], data: { breadcrumb: "Homepage" } },
    // Product
    { path: 'product/product-grid', loadChildren: () => import('./components/pages/product/product-grid/product-grid.module').then(m => m.ProductGridModule), canActivate: [AuthGuard], data: { breadcrumb: "Griglia Prodotti" } },
    { path: 'product/add-product', loadChildren: () => import('./components/pages/product/add-product/add-product.module').then(m => m.AddProductModule), canActivate: [AuthGuard], data: { breadcrumb: "Aggiungi Prodotto" } },
    { path: 'product/edit-product/:id', loadChildren: () => import('./components/pages/product/add-product/add-product.module').then(m => m.AddProductModule), canActivate: [AuthGuard], data: { breadcrumb: "Modifica Prodotto" } },
    { path: 'product/product-detail/:id', loadChildren: () => import('./components/pages/product/product-detail/product-detail.module').then(m => m.ProductDetailModule), canActivate: [AuthGuard], data: { breadcrumb: "Dettagli Prodotto" } },
    { path: 'menu/product-grid/:restaurant_id/:category_id', loadChildren: () => import('./components/pages/menu/product-grid/product-grid.module').then(m => m.ProductGridModule), data: { breadcrumb: "Menu" } },
    { path: 'menu/categories/:restaurant_id', loadChildren: () => import('./components/pages/menu/product-catalogue/product-catalogue.module').then(m => m.ProductCatalogueModule), data: { breadcrumb: "Categorie" } }, { path: 'menu/product-detail/:id', loadChildren: () => import('./components/pages/menu/product-detail/product-detail.module').then(m => m.ProductDetailModule), data: { breadcrumb: "Dettagli Prodotto" } },
    { path: 'menu/landing/:restaurant_id', loadChildren: () => import('./components/pages/menu/landing/landing.module').then(m => m.LandingModule), data: { breadcrumb: "HMenu" } },
    { path: 'ui-basic/accordions', loadChildren: () => import('./components/pages/ui-basic/accordions/accordions.module').then(m => m.AccordionsModule), data: { breadcrumb: "Accordions" } },
    // Ui-basic
    { path: 'ui-basic/alerts', loadChildren: () => import('./components/pages/ui-basic/alerts/alerts.module').then(m => m.AlertsModule), data: { breadcrumb: "Alerts" } },
    { path: 'ui-basic/buttons', loadChildren: () => import('./components/pages/ui-basic/buttons/buttons.module').then(m => m.ButtonsModule), data: { breadcrumb: "Buttons" } },
    { path: 'ui-basic/breadcrumbs', loadChildren: () => import('./components/pages/ui-basic/breadcrumbs/breadcrumbs.module').then(m => m.BreadcrumbsModule), data: { breadcrumb: "Breadcrumbs" } },
    { path: 'ui-basic/badges', loadChildren: () => import('./components/pages/ui-basic/badges/badges.module').then(m => m.BadgesModule), data: { breadcrumb: "Badges" } },
    { path: 'ui-basic/cards', loadChildren: () => import('./components/pages/ui-basic/cards/cards.module').then(m => m.CardsModule), data: { breadcrumb: "Cards" } },
    { path: 'ui-basic/progress-bars', loadChildren: () => import('./components/pages/ui-basic/progress-bars/progress-bars.module').then(m => m.ProgressBarsModule), data: { breadcrumb: "Progress Bars" } },
    { path: 'ui-basic/preloaders', loadChildren: () => import('./components/pages/ui-basic/preloaders/preloaders.module').then(m => m.PreloadersModule), data: { breadcrumb: "Pre-loaders" } },
    { path: 'ui-basic/pagination', loadChildren: () => import('./components/pages/ui-basic/pagination/pagination.module').then(m => m.PaginationModule), data: { breadcrumb: "Pagination" } },
    { path: 'ui-basic/tabs', loadChildren: () => import('./components/pages/ui-basic/tabs/tabs.module').then(m => m.TabsModule), data: { breadcrumb: "Tabs" } },
    { path: 'ui-basic/typography', loadChildren: () => import('./components/pages/ui-basic/typography/typography.module').then(m => m.TypographyModule), data: { breadcrumb: "Typography" } },
    { path: 'ui-advanced/sliders', loadChildren: () => import('./components/pages/ui-advanced/sliders/sliders.module').then(m => m.SlidersModule), data: { breadcrumb: "Sliders" } },
    // Ui-advanced
    { path: 'ui-advanced/modals', loadChildren: () => import('./components/pages/ui-advanced/modals/modals.module').then(m => m.ModalsModule), data: { breadcrumb: "Modals" } },
    { path: 'ui-advanced/rating', loadChildren: () => import('./components/pages/ui-advanced/rating/rating.module').then(m => m.RatingModule), data: { breadcrumb: "Rating" } },
    { path: 'ui-advanced/range-slider', loadChildren: () => import('./components/pages/ui-advanced/range-slider/range-slider.module').then(m => m.RangeSliderModule), data: { breadcrumb: "Range Slider" } },
    { path: 'form/form-elements', loadChildren: () => import('./components/pages/form/form-elements/form-elements.module').then(m => m.FormElementsModule), data: { breadcrumb: "Form Elements" } },
    // Forms
    { path: 'form/form-layouts', loadChildren: () => import('./components/pages/form/form-layouts/form-layouts.module').then(m => m.FormLayoutsModule), data: { breadcrumb: "Form Layouts" } },
    { path: 'form/form-validation', loadChildren: () => import('./components/pages/form/form-validation/form-validation.module').then(m => m.FormValidationModule), data: { breadcrumb: "Form Validation" } },
    { path: 'form/form-wizard', loadChildren: () => import('./components/pages/form/form-wizard/form-wizard.module').then(m => m.FormWizardModule), data: { breadcrumb: "Form Wizard" } },
    { path: 'icons/fontawesome', loadChildren: () => import('./components/pages/icons/fontawesome/fontawesome.module').then(m => m.FontawesomeModule), data: { breadcrumb: "Fontawesome Icons" } },
    // Icons
    { path: 'icons/flaticons', loadChildren: () => import('./components/pages/icons/flaticons/flaticons.module').then(m => m.FlaticonsModule), data: { breadcrumb: "Flaticons" } },
    { path: 'icons/materialize', loadChildren: () => import('./components/pages/icons/materialize/materialize.module').then(m => m.MaterializeModule), data: { breadcrumb: "Material Icons" } },
    { path: 'login', loadChildren: () => import('./components/pages/prebuilt-pages/default-login/default-login.module').then(m => m.DefaultLoginModule), data: { breadcrumb: "Login" } },
    // Prebuilt-pages
    // { path: 'prebuilt-pages/modal-login', loadChildren: () => import('./components/pages/prebuilt-pages/modal-login/modal-login.module').then(m => m.ModalLoginModule), data: { breadcrumb: "Modal Login" } },
    { path: 'register', loadChildren: () => import('./components/pages/prebuilt-pages/default-register/default-register.module').then(m => m.DefaultRegisterModule), data: { breadcrumb: "Registrazione" } },
    // { path: 'prebuilt-pages/modal-register', loadChildren: () => import('./components/pages/prebuilt-pages/modal-register/modal-register.module').then(m => m.ModalRegisterModule), data: { breadcrumb: "Modal Register" } },
    // { path: 'prebuilt-pages/lock-screen', loadChildren: () => import('./components/pages/prebuilt-pages/lock-screen/lock-screen.module').then(m => m.LockScreenModule), data: { breadcrumb: "Lock Screen" } },
    { path: 'prebuilt-pages/coming-soon', loadChildren: () => import('./components/pages/prebuilt-pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule), data: { breadcrumb: "Coming Soon" } },
    /*{ path: 'prebuilt-pages/error', loadChildren: () => import('./components/pages/prebuilt-pages/error/error.module').then(m => m.ErrorModule), data: { breadcrumb: "Error 404" } },
    { path: 'prebuilt-pages/faqs', loadChildren: () => import('./components/pages/prebuilt-pages/faqs/faqs.module').then(m => m.FaqsModule), data: { breadcrumb: "FAQ's" } },
    { path: 'prebuilt-pages/portfolio', loadChildren: () => import('./components/pages/prebuilt-pages/portfolio/portfolio.module').then(m => m.PortfolioModule), data: { breadcrumb: "Portfolio" } },*/
    { path: 'user-profile', loadChildren: () => import('./components/pages/prebuilt-pages/user-profile/user-profile.module').then(m => m.UserProfileModule), canActivate: [AuthGuard], data: { breadcrumb: "Profilo" } },
    { path: 'prebuilt-pages/invoice', loadChildren: () => import('./components/pages/prebuilt-pages/invoice/invoice.module').then(m => m.InvoiceModule), data: { breadcrumb: "Invoice" } },
    { path: 'plan/plan-grid', loadChildren: () => import('./components/pages/plan/plan-grid/plan-grid.module').then(m => m.PlanGridModule), canActivate: [AuthGuard], data: { breadcrumb: "I Nostri Piani" } },
    { path: 'plan/plan-detail/:id', loadChildren: () => import('./components/pages/plan/plan-detail/plan-detail.module').then(m => m.PlanDetailModule), canActivate: [AuthGuard], data: { breadcrumb: "Dettaglio Piano" } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
