import {AfterViewInit, Component, OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BreadcrumbService, Breadcrumb } from 'angular-crumbs';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
declare var gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit, AfterViewInit {
    private routerSubscription: Subscription;

  constructor(private titleService: Title, private breadcrumbService: BreadcrumbService, private metaTagService: Meta, private router: Router) {
  }
  ngOnInit(): void {
        this.breadcrumbService.breadcrumbChanged.subscribe(crumbs => {
            this.titleService.setTitle(this.createTitle(crumbs));
        });
        this.metaTagService.addTags([

             { name: 'keywords', content: 'menu digitale, menu, ristorante, qrcode menu, ristorazione, ristorante digitale, menu online' },

              { name: 'description', content: 'Crea e gestisci con semplicità i Menu del tuo ristorante, bar o pizzeria\n' +
                      'e scopri quali piatti attirano di più i clienti. Il menù digitale per crescere ed evolvere la tua attività' },

              { charset: 'UTF-8' }

        ]);
  }
  ngAfterViewInit() {
      this.routerSubscription = this.router.events
          .pipe(filter(event => event instanceof NavigationEnd))
          .subscribe((event: NavigationEnd) => {
              gtag('config', 'UA-343565455–1', {'page_path': event.urlAfterRedirects});
          });
  }



    onActivate(event){
    window.scroll(0,0);
  }
  private createTitle(routesCollection: Breadcrumb[]) {
    const title = 'HMenu';
    const titles = routesCollection.filter((route) => route.displayName);

    if (!titles.length) { return title; }

    const routeTitle = this.titlesToString(titles);
    return `${routeTitle} ${title}`;
  }

  private titlesToString(titles) {
    return titles.reduce((prev, curr) => {
      return `${curr.displayName} - ${prev}`;
    }, '');
  }
}
