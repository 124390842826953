import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL} from '../common/constants';
import {TokenStorageService} from './token-storage.service';
import {User} from '../model/user';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authUrl: string;
  private signupUrl: string;

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) {
    this.authUrl = API_URL.concat('/authentication');
    this.signupUrl = this.authUrl + '/signup';
  }

  login(username: string, password: string): Observable<any>{
    return this.http.post(this.authUrl + '/login', {
      username, password
    }, httpOptions);
  }

  logout(): void{
    this.tokenStorage.signOut();
  }

  isLoggedIn(): boolean{
    if (this.tokenStorage.getToken()){
      return true;
    }
    return false;
  }

  getUser(): any{
    return this.tokenStorage.getUser();
  }

  sendRequest(email: string): Observable<any>{
    return this.http.post(this.authUrl + '/token', {
      email
    }, httpOptions);
  }

  changePassword(token: string, password: string): Observable<any>{
    return this.http.post(this.authUrl + '/password-change', {
      token, password
    }, httpOptions);
  }

  reloadUser(user: any): void{
    this.tokenStorage.reloadUser(user);
  }


  signup(user: User): Observable<any>{
      return this.http.post<User>(this.signupUrl, user);
  }
}
