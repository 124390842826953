import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  public signOut(): void {
    window.sessionStorage.clear();
    window.localStorage.clear();
  }

  public saveToken(token: string, rememberMe: boolean): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(TOKEN_KEY);
    if (rememberMe)
    {
      window.localStorage.setItem(TOKEN_KEY, token);
    }
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    const token = window.sessionStorage.getItem(TOKEN_KEY) || window.localStorage.getItem(TOKEN_KEY);
    if (token) {
      if (this.tokenExpired(token)){
        return null;
      }
    }
    return token;
  }

  private tokenExpired(token: string): boolean {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  public saveUser(user: any, rememberMe: boolean): void {
    window.localStorage.removeItem(USER_KEY);
    window.sessionStorage.removeItem(USER_KEY);
    if (rememberMe)
    {
      window.localStorage.setItem(USER_KEY, JSON.stringify(user));
    }
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public reloadUser(user: any): void {
    let rememberMe = false;
    if (window.localStorage.getItem(USER_KEY)){
      rememberMe = true;
    }
    // console.log('reloadUser');
    this.saveUser(user, rememberMe);
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY) || window.localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }

}
